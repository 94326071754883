import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RootState } from '/store';

export interface IKBarState {
  searchInputFocused: boolean;
  openSelectAppModal: boolean;
  shouldUpdateApps: boolean;
}

const initialState: IKBarState = {
  searchInputFocused: false,
  openSelectAppModal: false,
  shouldUpdateApps: false,
};

const kbarSlice = createSlice({
  name: 'kbar',
  initialState,
  reducers: {
    setSearchInputFocused: (state, action: PayloadAction<boolean>) => {
      state.searchInputFocused = action.payload;
    },
    setOpenSelectAppModal: (state, action: PayloadAction<boolean>) => {
      state.openSelectAppModal = action.payload;
    },
    setShouldUpdateApps: (state, action: PayloadAction<boolean>) => {
      state.shouldUpdateApps = action.payload;
    },
    rehydrate: (state) => {
      _.merge(state, initialState);
    },
  },
});

export const kbarToolkit = Object.freeze({
  initialState,
  actions: kbarSlice.actions,
  selectors: {
    state: (state: RootState) => state.kbar,
    rehydrate: (state: RootState) => state.kbar,
    searchInputFocused: (state: RootState) => state.kbar.searchInputFocused,
    openSelectAppModal: (state: RootState) => state.kbar.openSelectAppModal,
    shouldUpdateApps: (state: RootState) => state.kbar.shouldUpdateApps,
  },
});

export const kbarReducer = kbarSlice.reducer;
